import { template } from '@shein/common-function'
import { labelTypeMap } from 'public/src/pages/components/product/item_v3/js/constant.js'
import schttp from 'public/src/services/schttp'
import { NonRefundableType } from 'public/src/pages/checkout/vue_tpls/shopping_bag/config.js'
import { ProductCardPriceAndDiscountInfo } from 'public/src/pages/components/product/item_v3/js/ProductCardPriceAndDiscountInfo/index.js'
/**
 * 获取支付限制
 * @param {Array} currencySupport 支付方式币种配置
 * @param {String} currency 当前币种
 * @param {String} paymentCode 支付方式编码，如 oxxo, paypal ..
 * @param {String} countryCode 运输国家简码，如 US, MX ..
 * ---------------
 * @returns {Boolean} isChangeCurrency 是否需要切换币种
 * @returns {Object} currentPayment 当前支付方式的币种配置
 */
export function handleSwitchCurrency ({ currencySupport = [], currency, paymentCode, countryCode }) {
  // 强制切换币种
  let isChangeCurrency = false
  let currentPayment = null // 当前可用的支付配置
  let currentPaymentWithoutCountryCode = null // 当不限制国家时的支付方式配置

  if (paymentCode) {
    // 获取当前支付方式配置
    const currentPaymentSupports = currencySupport.filter(item => item.payMethod == paymentCode)
    if (currentPaymentSupports.length == 0) {
      return {
        isChangeCurrency,
        currentPayment
      }
    }

    for (const i in currentPaymentSupports) {
      if (currentPaymentSupports[i].countryCode == countryCode && countryCode != '') {
        // 有限制国家的配置，直接取，跳出循环
        currentPayment = currentPaymentSupports[i]
        break
      }
      if (currentPaymentSupports[i].countryCode === null) {
        // 非国家限制，兜底
        currentPaymentWithoutCountryCode = currentPaymentSupports[i]
      }
    }

    // 优先取有国家限制的支付方式，没有就取无国家限制的
    currentPayment = currentPayment || currentPaymentWithoutCountryCode

    if (currentPayment) {
      const { currencySupportList = [], defaultCurrency } = currentPayment
      const isSupport = currencySupportList.some(item => item == currency)

      // 若不支持此币种
      if (!isSupport && defaultCurrency) {
        isChangeCurrency = true
      }
    }
  }

  return {
    isChangeCurrency,
    currentPayment
  }
}

/**
 * 获取营销裂变提示语
 * @param {Object} extraPromotion 营销裂变信息对象
 */
export function transformExtraPromotionTip ({ language = {}, extraPromotion = {} } = {}) {
  const rule = extraPromotion?.marketFission?.rule || {}
  const { reason = 0, differencePrice } = rule
  if (reason == 0) return
  const priceTag = `<em class="tip-red">${differencePrice.amountWithSymbol}</em>`

  const reasonLanuageMap = {
    '1': language.SHEIN_KEY_PWA_18108,
    '2': language.SHEIN_KEY_PWA_18109,
    '3': language.SHEIN_KEY_PWA_18110,
    '4': language.SHEIN_KEY_PWA_18111,
  }
  return template(priceTag, reasonLanuageMap[reason])
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    item?.product?.product_promotion_info?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

const getXtraCouponCode = ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  return xtraProductBagInfo.map(val => {
    let coupon_code = ''
    const discountInfo = xtraDiscountInfo?.allPaymentRightInfo?.[val.product_code] || {}
    const { total_discount } = discountInfo
    
    if(+total_discount?.amount > 0) {
      coupon_code = discountInfo.discount_values?.[0]?.coupon_code || ''
    } else {
      const rightConfig = val.right_config_list?.filter(item => item.privilege_cycle_num == 1) || []
      const privilege_info_list = rightConfig[0]?.privilege_info_list || []

      coupon_code = privilege_info_list[0]?.coupon_code_list?.[0]?.coupon_code || ''
    }

    return ({ [val.product_code]: coupon_code.toUpperCase() })
  })
}

const getXtraCouponType = ({ productInfo, couponCode }) => {
  const privilegeInfoList = productInfo.right_config_list?.map(item => item.privilege_info_list).flat(Infinity) || []
  const couponCodeList = privilegeInfoList.map(item => item.coupon_code_list).flat(Infinity) || []
  const couponTypeIndex = couponCodeList.findIndex(coupon => coupon.coupon_code?.toUpperCase() == couponCode.toUpperCase())

  return couponCodeList[couponTypeIndex]?.coupon_style || 0
}

export const getXtraDisplayData = async ({ xtraProductBagInfo = [], xtraDiscountInfo = {} }) => {
  const couponCodeData = getXtraCouponCode({ xtraProductBagInfo, xtraDiscountInfo })
  const coupon_code = couponCodeData.map(item => Object.values(item)?.[0] || '')

  const data = await getCouponListForXtra(coupon_code) || []

  let result = {}
  xtraProductBagInfo?.forEach(val => {
    const productCode = val?.product_code
    const couponCode = couponCodeData.find(i => !!i[productCode])?.[productCode] || ''

    const count = val.right_config_list?.map(item => +item.coupon_num).reduce((prev, curr) => {
      return prev + curr
    }, 0) || 0

    const couponType = getXtraCouponType({ productInfo: val, couponCode })
    result[productCode] = {
      type: couponType, count, couponCode,
      info: data?.find(_ => _.couponCode.toUpperCase() == couponCode) || {}
    }
  })
  return { data: result }
}

export const getCouponListForXtra = (coupon_codes) => {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/coupon/getCouponListForXtra/query',
      data: {
        coupon_codes: coupon_codes,
        ignore_check: 1,
      }
    }).then(res => {
      if(res.code == 0 && res.info) {
        resolve(res.info.list || [])
      } else {
        resolve([])
      }
    }).catch(() => {
      resolve([])
    })
  })
}

export function publishCartCheckout(){
  let cacheCartInfoStr = sessionStorage.getItem('tpmCacheCart')
  if(cacheCartInfoStr) {
    let cacheCartInfo = null
    try{
      cacheCartInfo = JSON.parse(cacheCartInfoStr)
    } catch(e) {
      console.log(e)
    }
    if(cacheCartInfo) {
      window.TPM?.publish('checkout', {
        carts: cacheCartInfo.carts, 
        currency: cacheCartInfo.currency,
        prices: { totalPrice: cacheCartInfo.totalPrice }
      })
    }
    sessionStorage.removeItem('tpmCacheCart')
  }
}

export function debuggerLog(...args) {
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info('%cCheckout:', 'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff', ...args, '\n')
  } catch (e) {
    console.log(...args)
  }
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} products 需要上报的已曝光推荐商品列表
 * @param {Number} location 1: 支付方式上方; 2: 支付方式下方; 3: 虚拟资产下方;
 * @param {Number} index 商品的位置，从上到下，从左到右
 * @return {String}
 */
export const getGoodsListForAnalysis = (products, location, newCard, cardConfig) => {
  let exposedList = products

  // goods_id`skc`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`价格标识`其它标识`销售或促销属性`mall_mallcode`算法额外标识
  const reportKeys = [
    'goodsId',
    'skc',
    'spuId',
    'index',
    'page',
    'location',
    'recMark',
    'extraMark',
    'priceMark',
    'otherMark',
    'promotion',
    'salesLabels',
    'mallCode',
    'algorithmExtraMark'
  ]
  let results = exposedList.map((item, index) => {
    const { 
      quickship, 
      promotionInfo, 
      brand,
      goods_id, 
      goods_sn, 
      productRelationID,
      ext,
      dynamic_ext,
      salePrice,
      retailPrice,
      mall_code,
      sku_attr_list,
      mobileVerticalView,
      swiperIndex,
      pretreatInfo
    } = item
    let otherMark = [], salePromotion = []
    // 其他标识 - quickship
    // otherMark.push(`quickship_${quickship}`)
    if (quickship) otherMark.push(`show_service_label_QuickShip`)
    
    // 销售属性
    const valueNames = sku_attr_list?.filter(i => i.is_main === 0)?.map(i => i.attr_value_multi_name)?.join('/')
    salePromotion.push(valueNames ? `attribute_show_${valueNames}` : 'attribute_null')
    // 促销属性
    if (promotionInfo?.length) {
      let is_brand = brand?.name ? 1 : 0
      let brandCode = brand?.brand_code
      const isABPrice = promotionInfo?.some(item => +item?.typeId === 32)
      
      isABPrice && otherMark.push(isABPrice)

      promotionInfo.forEach(ele => {
        salePromotion.push(['sale', ele.typeId, ele.id, is_brand, brandCode].join('_') )
      })
      const isSubsidiesGoods = (item?.promotionInfo || []).find(info => +info.typeId === 31 && +info.promotion_logo_type === 21)
      if (isSubsidiesGoods && cardConfig?.show10BillionSubsidies) {
        otherMark.push('brandDeals')
      }
    }
    // 品类首金
    if (pretreatInfo?.brandDealsAttributeLabel?.labelType === 'userVoucher') {
      otherMark.push('listnewuserVoucher')
    }

    if (cardConfig && goods_id && typeof window !== 'undefined') {
      const priceAndDiscountInfo = new ProductCardPriceAndDiscountInfo({
        goodsInfo: item, 
        config: cardConfig,
        isPaid: cardConfig.showSheinClubPriceOnSale
      })

      if (priceAndDiscountInfo?.priceBottomRrp?.show) {
        otherMark.push('list_rrp_price')
      }
    }

    const values = {
      goodsId: goods_id,
      skc: goods_sn,
      spuId: productRelationID,
      index: swiperIndex !== undefined ? Number(swiperIndex) + 1 : index + 1,
      page: 1,
      location,
      recMark: ext?.rec_mark ?? '',
      extraMark: dynamic_ext?.extra ?? '',
      priceMark: `pri_${salePrice?.usdAmount}|pri_${retailPrice?.usdAmount}`,
      otherMark: otherMark.length ? otherMark.join('|') : '',
      promotion: salePromotion.length ? salePromotion.join('|') : '',
      mallCode: `mall_${mall_code}`,
      algorithmExtraMark: dynamic_ext?.other_d_ext_mark ?? '',
      salesLabels: mobileVerticalView?.salesLabels?.labelLang && newCard == 'A' ? `show_sales_label_${labelTypeMap.get(mobileVerticalView?.salesLabels.textType)}_${mobileVerticalView?.salesLabels.labelId}_${mobileVerticalView?.salesLabels.labelLang?.replace(/\s+/g, '_')}` : '',
      rrpPrice: ''
    }

    return reportKeys.map(key => values[key]).join('`')
  })

  return results.join(',')
}
export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  
  let copy = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy
}

// abt/没有快捷 => 自动续费包过滤掉
export function autoPrimeProducfilter({ sheinClubRenew, primeProductInfo, paymentMethods }){
  let { primeProductList, limitedPayTypeList } = primeProductInfo
  const limitedPayTypeListCode = (limitedPayTypeList || []).map(m => m.code)
  const isLimitedPayUsed = paymentMethods.some(s => limitedPayTypeListCode.includes(s.code) && s.enabled == 1)
  
  if(sheinClubRenew != 1 || !limitedPayTypeList || limitedPayTypeList?.length <= 0 || !isLimitedPayUsed){
    primeProductList = primeProductList.filter(f => f?.product_activity_type != 1)
  }
  return primeProductList
}

export function cartBusinessModelList(currentThis){
  const carts = currentThis?.checkout?.results?.carts?.carts || []
  return carts?.length ? [...new Set(carts.map(m => m?.business_model))] : []
}

/**
 * 根据不同的状态，输出场景
 * 是否有特价showDiscount、是否有分人群活动价isPreciseType、是否有可用的会费劵couponRightsCode、是否已勾选超省卡usedXtraCode、是否已经在下单页实际使用了会费券applyCouponsVir
 *  */
export const scenceBuildResult = ({ showDiscount, isPreciseType, couponRightsCode, usedXtraCode, applyCouponsVir }
= { showDiscount: false, isPreciseType: false, couponRightsCode: false, usedXtraCode: false, applyCouponsVir: false }) => {
  let result = 0
  if(!showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 1
  if(!showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 2
  if(showDiscount && !isPreciseType && !couponRightsCode && !usedXtraCode)result = 3
  if(showDiscount && !isPreciseType && !couponRightsCode && usedXtraCode)result = 4
  if(isPreciseType && !couponRightsCode && usedXtraCode)result = 6
  if(!showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 7
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 9
  if(!showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 8
  if(showDiscount && !isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 10
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 11
  if(showDiscount && !isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 12
  if(isPreciseType && couponRightsCode && !usedXtraCode && !applyCouponsVir)result = 13
  if(isPreciseType && couponRightsCode && usedXtraCode && !applyCouponsVir)result = 14
  if(isPreciseType && couponRightsCode && usedXtraCode && applyCouponsVir)result = 15
  if(isPreciseType && !couponRightsCode && !usedXtraCode) result = 5
  
  return result
}

// 超省卡根据包code，输出场景下对应的状态
export const scenceBuildStatus = (xtraAllproduct = [], showTipsArrowAbt = false) => {
  const sceneResultAll = deepCopy(xtraAllproduct)
  let resultObjct = {
    'default': {
      isVisible: false,
      iconDoubt: false,
      isTips: false,
      isLeg: false
    }
  }
  if(!sceneResultAll?.length) return resultObjct
  sceneResultAll.forEach(m => {
    const result = {
      isVisible: [10, 12, 13, 15].includes(m._xtraSceneDiscount) ? true : false, // 点击是否出优惠弹窗
      iconDoubt: [13, 15].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, // 优惠弹窗里面是否展示法务弹窗的问号
      isTips: m.product_activity_type == 1 ? [7, 8, 11, 14].includes(m._xtraSceneDiscount) : [8, 11, 14].includes(m._xtraSceneDiscount), // 跳转到券中心（如果是自动续费产品包的时候，需要增加场景7）
      isLeg: [5, 6].includes(m._xtraSceneDiscount) && showTipsArrowAbt ? true : false, //点击是否出现法务弹窗
      productInfo: m, // 包体信息
      _xtraSceneDiscount: m._xtraSceneDiscount, // 当前是在哪个场景
    }
    resultObjct[m.product_code] = result
  })
  return resultObjct
}

/**
 * 根据本地化尺码获取展示的尺码
 * @return {String}
 */
export const getProductSize = ({ 
  abt = 'old', 
  sizeAttr = {}, 
  sizeRuleList = [], 
  cacheLocalCountry 
}) => {
  const sizeRuleItem = sizeRuleList.find(item => (
    item.name === sizeAttr.attr_value_name_en || 
      item.size === sizeAttr.attr_value_name_en
  ))
  // 默认尺码
  const defaultSize = sizeAttr.attr_value_name
  // 本地尺码，若没有缓存站点，则为当前站点的本地尺码
  const localSize = sizeRuleItem?.correspond
  const sizeInfo = {
    'old': {
      cartSize: localSize ? `${localSize}(${defaultSize})` : defaultSize,
      localSize: localSize ? `${localSize}(${defaultSize})` : defaultSize
    },
    'new': {
      cartSize: defaultSize,
      localSize: localSize ? `${localSize}(${defaultSize})` : defaultSize
    },
    'local_size_no_default': {
      cartSize: localSize || defaultSize,
      localSize: localSize || defaultSize
    },
    'default_size_no_default': {
      cartSize: defaultSize,
      localSize: localSize || defaultSize
    }
  }

  const sizeByAbt = sizeInfo[abt] || sizeInfo.old

  if (cacheLocalCountry) {
    if (cacheLocalCountry === 'default') return defaultSize
    return sizeByAbt.localSize
  }

  return sizeByAbt.cartSize
}
/* 
* 传入秒级
* startTime 开始时间
* endTime 结束时间
* onCompleted 倒计时结束回调
* cb 倒计时回调 输出 时分秒
*/
export function useCountDown (options = { startTime: '', endTime: '', interval: 1000, onCompleted: null }, cb = () => {}) {
  let { startTime, endTime, interval = 1000, onCompleted = () => {} } = options
  if(!+startTime || !+endTime || endTime - startTime < 1){
    cb({ days: 0, hours: 0, minutes: 0, seconds: 0, seconds_transform: '00', days_transform: '00', hours_transform: '00', minutes_transform: '00', expired: false })
    if (typeof onCompleted === 'function') onCompleted()
    return
  }
  let timer = null
  let countDownTime = (endTime - startTime) * 1000
  
  const parseMs = (milliseconds) => {
    const timeObje = {
      days: Math.floor(milliseconds / 86400000),
      hours: Math.floor(milliseconds / 3600000) % 24,
      minutes: Math.floor(milliseconds / 60000) % 60,
      seconds: Math.floor(milliseconds / 1000) % 60,
    }
    const results = {}
    Object.entries(timeObje).forEach((item)=>{
      if(item[1] < 10){
        results[`${item[0]}_transform`] = `0${item[1]}`
      } else {
        results[`${item[0]}_transform`] = String(item[1])
      }
    })

    return {
      ...timeObje,
      ...results,
      expired: !timeObje.days && !timeObje.hours && !timeObje.minutes && !timeObje.seconds
    }
  }
  
  const clear = () => {
    countDownTime = 0
    clearInterval(timer)
  }
  
  if (countDownTime > 0) {
    cb({
      ...parseMs(countDownTime),
      clear
    })
  
    !timer && (timer = setInterval(() => {
      countDownTime -= interval
      if (countDownTime <= 0) {
        clear()
        if (typeof onCompleted === 'function') onCompleted()
      }
      cb({
        ...parseMs(countDownTime),
        clear
      })
    }, interval))
  }
}

/**
 * 判断该商品是否可退
 * @param {String} SiteUID 站点ID
 * @param {Object} item 商品信息
 * @return {Object} { nonRefundable, nonRefundableInfo } nonRefundable: 是否为不可退商品  nonRefundableInfo: 不可退详情
 */
export function isProductRefundable(SiteUID, item) {
  const isDe = !!~SiteUID.indexOf('de')
  // return_flag: 1-商品分类不可退 2-毛利率过低不可退 3-商品参加活动不可退
  const nonRefundableInfo = {
    // 定制商品不可退
    [NonRefundableType.Customized]: item.product?.customization_flag == 1,
    // 品类不可退
    [NonRefundableType.Category]: [1].includes(+item.return_flag),
    // 促销不可退
    [NonRefundableType.Promotion]: [3].includes(+item.return_flag),
    // 低毛利不可退
    [NonRefundableType.LowGrossMargin]: [2].includes(+item.return_flag),
    // 撤销权不可退（de市场）
    [NonRefundableType.Withdrawal]: isDe && !!item.nonRefundable,
    // 撤销权可退（促销不可退、低毛利不可退、品类不可退）（de市场）
    [NonRefundableType.RefundableWithdrawal]: isDe && [1, 2, 3].includes(+item.return_flag)
  }

  return {
    // 只要有一个不可退类型满足，则该商品不可退
    nonRefundable: Object.values(nonRefundableInfo).some(nonRefundable => !!nonRefundable),
    nonRefundableInfo
  }
}

// 运输方式qs时效方法拼装
export const shippingDescribeFn = ({ item, timePercentAbt, quickShipCheckoutStatus } = { item: {}, timePercentAbt: false, quickShipCheckoutStatus: {} }) => {
  if (!item) return ''

  let key = 0; let _html = ''

  if (item.desc_type == 1) {
    _html = item.desc_expect_time ? item.desc_expect_time.split(/&amp;&amp;|&&/)[key] : ''
    if (timePercentAbt) _html += item?.delivery_day_percent_desc || ''
  } else {
    _html = item.desc_shipping_time ? item.desc_shipping_time.split(/&amp;&amp;|&&/)[key] : ''
    if (timePercentAbt) _html += item?.shipping_day_percent_desc || ''
  }

  const replaceValue = item.cmmdty_infos?.length ? 
    `<i class="sh_m_sui_icon_sensitive_16px"></i>` : ''
  _html = _html.replace('{sensitive_icon}', replaceValue)

  if( _html ){
    _html = _html.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')

    quickShipCheckoutStatus?.quickShipLabelText && (_html = _html.replace(/{QSname}/g, `<span style="color:#198055;font-weight: 400;">${quickShipCheckoutStatus.quickShipLabelText}</span>`)
      .replace(/\[time\]/g, `<span style="color:#198055">${item.quick_ship_time}</span>`))
  }
  return _html
}
